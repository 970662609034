import Robusta from "../assets/images/products/Robusta.jpg";
import Espresso from "../assets/images/products/Espresso.jpg";
import Mocha from "../assets/images/products/Mocha.jpg";
import FrenchPress from "../assets/images/products/FrenchPress.jpg";
import ComingSoon from "../assets/images/products/coming-soon.jpg";
import CoffeeFilterPaper from "../assets/images/products/CoffeeFilterPaper.jpg";
import MilkFrothersCoffee from "../assets/images/products/milk-frothers-coffee.jpg";
import GreenCoffeeBeans from "../assets/images/products/gear1.jpeg";
import ArabicaCoffee from "../assets/images/products/arabica-coffee.jpg";
import Carafes from "../assets/images/products/carafes.jpg";
import CoffeeGrinderBeans from "../assets/images/products/coffee-grinder-beans.jpg";
import DecafCoffee from "../assets/images/products/decaf-coffee.jpg";
import ElectricGrinderCoffee from "../assets/images/products/gear3.jpeg";
import GroundCoffee from "../assets/images/products/ground-coffee.jpg";
import InstantCoffee from "../assets/images/products/instant-coffee.jpg";
import ManualCoffeeGrinders from "../assets/images/products/gear2.jpeg";
import PowderSprinklers from "../assets/images/products/Powder-Sprinklers.jpg";
import SouthIndianFilterCoffee from "../assets/images/products/south-Indian-Filter-Coffee.jpg";
import Stencils from "../assets/images/products/gear4.jpeg";
import Kaapi from "../assets/images/products/kaapiCoffee.jpg";
import Jolt from "../assets/images/products/joltCoffee.jpg";
import Vintage from "../assets/images/products/vintageCoffee.jpg";
import StovetopEspressoMaker from "../assets/images/products/Stovetop-Espresso-Maker.jpg";

export const productsData = [
  // {
  //   id: "02",
  //   name: "Stovetop Espresso Makers",
  //   description: "Stovetop Espresso Makers",
  //   images: StovetopEspressoMaker,
  //   price: 1090,
  //   category: "coffeeFilters",
  // },
  // {
  //   id: "03",
  //   name: "Mocha",
  //   description: "Mocha",
  //   images: Mocha,
  //   price: 1050,
  //   category: "coffeeFilters",
  // },
  {
    id: "04",
    name: "Instant Coffee",
    description: "InstantCoffee",
    images: InstantCoffee,
    price: 0,
    category: "coffee",
  },
  {
    id: "54",
    name: "VINTAGE Coffee",
    description: "VINTAGE is our signature freeze-dried coffee, a medium roast blend, which is mild, aromatic, rich and smooth. <br/> Experience this rich and aromatic flavor crafted with the world's finest Arabica coffee beans. <br/><br/>VINTAGE is crafted using 100% pure Arabica coffee beans known for their smooth, mellow, and rich flavor profile. <br/><br/>100g",
    images: Vintage,
    price: 0,
    category: "coffee",
  },
  {
    id: "56",
    name: "JOLT Coffee",
    description: "Jolt is our signature blend of Instant Coffee and Chicory Mixture to elevate your daily coffee routine. With a harmonious blend of premium coffee and high-quality chicory, JOLT promises a unique and satisfying taste that's perfect for any occasion. It's a true indulgence for coffee lovers. <br/> The chicory in our mixture complements the coffee perfectly, creating a smoother and less bitter taste. You'll love the balanced finish with every sip. <br/><br/> 100g",
    images: Jolt,
    price: 0,
    category: "coffee",
  },
  {
    id: "57",
    name: "KAAPI Coffee",
    description: "KAAPI is our signature South Indian filter coffee powder. A carefully balanced blend of high-quality Arabica and Robusta coffee beans, expertly roasted and mixed with chicory to create a harmonious flavor profile. Experience the Rich Flavor of South Indian Filter Coffee. KAAPI is renowned for its unique brewing method and the use of chicory to enhance the aroma and texture. Our KAAPI brings this tradition to your cup. It is easy to use with a traditional South Indian coffee filter or even a drip machine. The moment you open the seal, you'll be greeted by the tantalizing aroma of freshly ground coffee and chicory. It's a sensory experience that sets the stage for a delightful coffee journey.",
    images:  Kaapi   ,
    price: 0,
    category: "coffee",
  },
  {
    id: "05",
    name: "Ground Coffee",
    description: "Ground Coffee",
    images: GroundCoffee,
    price: 0,
    category: "coffee",
  },
  {
    id: "06",
    name: "South Indian Filter Coffee",
    description: "South Indian Filter Coffee",
    images: SouthIndianFilterCoffee,
    price: 0,
    category: "coffee",
  },
  {
    id: "07",
    name: "Decaf",
    description: "Decaf",
    images: DecafCoffee,
    price: 0,
    category: "coffee",
  },
  {
    id: "08",
    name: "Arabica",
    description: "Coffee Gable is committed to deliver the most exquisite Arabica coffee beans to your doorstep. Our beans are meticulously sourced from the world's most renowned coffee-producing regions, where the soil, climate, and altitude come together to create coffee beans of unparalleled excellence. <br/> <br/> If you are a coffee connoisseur seeking the finest flavors and aromas in your daily brew, you just found Coffee Gable's premium Arabica coffee beans. Grown at higher elevations in regions with ideal climates and conditions, these beans are celebrated for their smooth, mild flavor, and subtle acidity.To ensure the freshness and flavor of every batch, the beans are roasted in small quantities by our experienced roasters. This means you'll experience the true essence of these beans in every cup you brew. <br/> <br/>  Elevate your coffee experience with our finest Arabica coffee beans. From the Break of dawn to those cherished moments of relaxation, our beans will redefine your coffee rituals. <br/><br/> 300 gm",
    images: ArabicaCoffee,
    price: 0,
    category: "coffeeBeans",
  },
  {
    id: "01",
    name: "Robusta",
    description: "Coffee Gable is committed to deliver the most exquisite Arabica coffee beans to your doorstep. Our beans are meticulously sourced from the world's most renowned coffee-producing regions, where the soil, climate, and altitude come together to create coffee beans of unparalleled excellence. <br/><br/>The beans are carefully selected, meticulously roasted, and expertly packaged to ensure you experience the full, unadulterated richness of Robusta in every cup. Whether you prefer your coffee brewed as an intense espresso shot or in a smooth, strong French press, our Robusta coffee beans deliver an exceptional taste that suits various brewing methods. Elevate your coffee experience to new heights and savor the distinctive flavors of Robusta. <br/> <br/> 300gm",
    images: Robusta,
    price: 1000,
    category: "coffeeBeans",
  },
  {
    id: "09",
    name: "Green coffee Beans",
    description: "Coffee Gable is committed to deliver the most exquisite Arabica coffee beans to your doorstep. Our beans are meticulously sourced from the world's most renownedcoffee-producing regions, where the soil, climate, and altitude come together to create coffee beans of unparalleled excellence.<br/><br/>Our premium green coffee beans are the perfect solution for those seeking a higher-quality coffee experience while reaping the incredible health benefits. Green coffeebeans are bursting with antioxidants that help combat free radicals, promoting overall well-being and a youthful glow. Chlorogenic acid in green coffee beans may aidin weight management by supporting healthy metabolism and reducing the absorption of carbohydrates.<br/><br/>Roasting your green coffee beans at home is a rewarding and enjoyable experience. It allows you to customize your coffee's roast level to suit your taste. Whether youprefer a light, medium, or dark roast, you're in control.<br/><br/>Unlock the true potential of coffee with our green beans. Your daily brew will never be the same!<br/><br/>Remember, each sip is an invitation to a healthier and more flavorful lifestyle. Enjoy the ultimate coffee experience with our premium green coffee beans.<br/><br/> 300 gm",
    images: GreenCoffeeBeans,
    price: 0,
    category: "coffeeBeans",
  },
  {
    id: "10",
    name: "Coffee Bean Grinders",
    description: "Coffee Bean Grinders",
    images: CoffeeGrinderBeans,
    price: 0,
    category: "coffeeGear",
  },
  {
    id: "11",
    name: "Manual Grinders",
    description: "Manual Grinders",
    images: ManualCoffeeGrinders,
    price: 0,
    category: "coffeeGear",
  },
  {
    id: "12",
    name: "Electric Grinders",
    description: "Electric Grinders",
    images: ElectricGrinderCoffee,
    price: 0,
    category: "coffeeGear",
  },
  {
    id: "13",
    name: "Paper Coffee Filters",
    description: "Paper Coffee Filters",
    images: CoffeeFilterPaper,
    price: 0,
    category: "coffeeGear",
  },
  // {
  //   id: "14",
  //   name: "South Indian Filter Coffee Makers",
  //   description: "South Indian Filter Coffee Makers",
  //   images: SouthIndianFilterCoffee,
  //   price: 0,
  //   category: "coffeeFilters",
  // },
  // {
  //   id: "15",
  //   name: "French Press Coffee Makers",
  //   description: "French Press Coffee Makers",
  //   images: FrenchPress,
  //   price: 0,
  //   category: "coffeeFilters",
  // },
  {
    id: "16",
    name: "Milk Frothers",
    description: "Milk Frothers",
    images: MilkFrothersCoffee,
    price: 0,
    category: "coffeeGear",
  },
  {
    id: "17",
    name: "Carafes",
    description: "Carafes",
    images: Carafes,
    price: 0,
    category: "coffeeGear",
  },
  {
    id: "18",
    name: "Stencils",
    description: "Stencils",
    images: Stencils,
    price: 0,
    category: "coffeeGear",
  },
  // {
  //   id: "19",
  //   name: "Powder Sprinklers ",
  //   description: "Powder Sprinklers ",
  //   images: PowderSprinklers,
  //   price: 0,
  //   category: "brewingAccessories",
  // },

  {
    id: "21",
    name: "Kettles",
    description: "Kettles",
    images: Espresso,
    price: 0,
    category: "coffeeGear",
  },
  {
    id: "22",
    name: "Coofee Mugs",
    description: "",
    images: ComingSoon,
    price: 0,
    category: "coffeeGear",
    comingSoon: true,
  },
  {
    id: "23",
    name: "",
    description: "",
    images: "",
    price: 0,
    category: "gifting",
    comingSoon: true,
  },
  {
    id: "24",
    name: "Barista Tools Kits",
    description: "Barista Tools Kits",
    images: ComingSoon,
    price: 0,
    category: "coffeeGear",
    comingSoon: true,
  },
];
