import "./About.css";
import AboutUs from "../../assets/images/about-us.png";
import landingSeparator from "../../assets/images/landing-separator-1.png";

const About = () => {
  return (
    <div>
      <div class="">
        <img
          src={AboutUs}
          className="about-us-image"
          width={"100%"}
          alt="contact-img"
        />
      </div>
      <div className="heading_div mt-4">
        <h1>About Us </h1>{" "}
        <img className=" " src={landingSeparator} alt="LandingLogo3" />
      </div>
      <div className=" about-us-content d-flex justify-content-center align-items-center ">
        <p>
          Welcome to Coffee Gable

          Coffee Gable is dedicated to crafting exceptional coffee experiences that awaken your senses. From our lush coffee farms to your cup, we pour our passion into every bean.

          At Coffee Gable, we believe that coffee is more than just a beverage; it's an experience, a culture, and a way of life. With a deep-rooted love for coffee, we have embarked on a journey to bring you the finest selection of coffee beans, curated and roasted to perfection.  Whether you're a seasoned coffee connoisseur or just beginning your coffee journey, we invite you to join us in celebrating the art and craft of coffee. From sourcing the finest beans to perfecting every roast, our passion is to bring you a cup that awakens your senses and fuels your day. We are dedicated to providing rich flavors, bold aromas, and moments of joy in every brew.

          With a commitment to quality, sustainability, and community, we are your trusted partner in every sip of your coffee journey. Welcome to the perfect blend of craft and passion
        </p>
      </div>
    </div>
  );
};

export default About;
